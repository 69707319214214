@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@fontsource/montserrat";
@import "@fontsource/montserrat";

@font-face {
  font-family: "MontserratBlack";
  src: url("../assets/fonts/Montserrat-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratMedium";
  src: url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratRegular";
  src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratSemiBold";
  src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratThin";
  src: url("../assets/fonts/Montserrat-Thin.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MontserratLight";
  src: url("../assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;

  font-family: "Montserrat", sans-serif;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.swiper-button-next,
.swiper-button-prev {
  visibility: hidden;
}

@media only screen and (min-width: 600px) {
  .swiper-button-next,
  .swiper-button-prev {
    visibility: visible;
  }
}

.opacity-35 {
  opacity: 0.65;
}

.text-content {
  font-feature-settings: "liga" off, "clig" off;
}

@keyframes smartAnimate {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.8;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.variant2 {
  animation: smartAnimate 300ms ease-out;
}

#parallax-container {
  position: relative;
  overflow: hidden;
}

#parallax-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/images/badge-background.svg");
  background-position: top;
  background-size: cover;
  z-index: -1; /* Push the background behind the content */
}

/* INFINITY SCROLL HORIZONTAL */

.infinity-image {
  max-width: 150px;
  height: 5rem;
  padding: 0 15px;
  object-fit: cover;
}

.infinity-image:last-of-type {
  padding-left: 0;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.wrapper {
  position: absolute;
  display: flex;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.section {
  animation: scroll var(--speed) linear infinite;
}
